import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

import ShareBanner from "../components/UI/share-banner"
import ActionButton from "../components/UI/call-to-action"

const ServiceWrapper = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  align-items: center;

  .content-block {
    margin: 2em;
    display: block;
    width: 70%;
  }

  p {
    font-size: 1.2em;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    .content-block {
      width: 80%;

      h2 {
        text-align: center;
      }
    }
  }
`

const Services = ({ data }) => {
  const { call_services, service } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  //When mapping from an object use Object.values
  const ListElement = service.map((service, i) => {
    const isLeft = i % 2 === 1

    return (
      <ServiceWrapper
        style={{ flexDirection: isLeft ? "row" : "row-reverse" }}
        key={service.title}
      >
        {service.image && (
          <GatsbyImage
            image={getImage(service.image)}
            alt={"" + i + ""}
            style={{ margin: isLeft ? "0 0 0 -9em" : "0 -9em 0 0" }}
          />
        )}
        <div className="content-block">
          <h2>{service.title}</h2>
          <p
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: service.body }}
          />
        </div>
      </ServiceWrapper>
    )
  })

  return (
    <Layout>
      {ListElement}
      <ActionButton button="Our Work" link="/our-work">
        {call_services}
      </ActionButton>
      <div className="layout-box">
        <ShareBanner />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "services" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              call_services
              service {
                body
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [PNG, WEBP, AVIF]
                      layout: CONSTRAINED
                      quality: 100
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Services
